import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  siteReady: boolean = false;
  private siteReadyListener: Subscription;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.siteReadyListener = this.authService
      .getSiteReadyObservable()
      .subscribe((ready) => {
        this.siteReady = ready;
      });
  }

  ngOnDestroy(): void {
    if (this.siteReadyListener) {
      this.siteReadyListener.unsubscribe();
    }
  }
}
