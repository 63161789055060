// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  kconnectAPI: 'https://gateway.test.kayool.com/kayool-shop-service',
  SECRET_KEY: 'kC0nnectDev@2o23',
  websocketUrl: 'https://gateway.test.kayool.com:9401/kayoolshop/socket',
  dashboardURL: 'https://connectnowdashboarddevsite.kayool.com/#/',
  connectNowURL: 'https://connectnowdevsite.kayool.com/#/',

  razorpayKey: 'rzp_test_iGDJvGZhREs32U',
  cryptoSecretKey: 'Kay00!Secre1@#2o23',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
